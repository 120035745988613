import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik } from "formik";
import { link } from "fs";
import { Link } from "react-router-dom";

const Home = () => {

    const api_domain = "https://domserwismeble.pl";

    const [homeOffer, setHomeOffer] = useState([]);
    const [homeOfferDesc, setHomeOfferDesc] = useState([]);

    const fetchHomeOfferCall = async () => {
        let apiReturn = await axios
            .get(api_domain + "/domserwismeble/api/collections/get/Home_offer?token=f7edd2e09e8bc0678ef2ea908889d3")
            .then(async function (response) {
                localStorage.setItem('home_offer', JSON.stringify(response.data.entries));
            })
            .catch(function (error) {
                console.log(error);
            });
        return apiReturn;
    };

    const fetchHomeOfferDescCall = async () => {
        let apiReturn = await axios
            .get(api_domain + "/domserwismeble/api/collections/get/Home_offer_desc?token=f7edd2e09e8bc0678ef2ea908889d3")
            .then(async function (response) {
                localStorage.setItem('home_offer_desc', JSON.stringify(response.data.entries));
            })
            .catch(function (error) {
                console.log(error);
            });
        return apiReturn;
    };

    if (localStorage.getItem("home_offer") === null) {
        fetchHomeOfferCall();
        setTimeout(function () {
            window.location.reload();
        }, 200);
    }

    if (localStorage.getItem("home_offer_desc") === null) {
        fetchHomeOfferDescCall();
        setTimeout(function () {
            window.location.reload();
        }, 200);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            fetchHomeOfferCall();
            fetchHomeOfferDescCall();
        }, 30000);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        setHomeOffer(JSON.parse(localStorage.home_offer));
        setHomeOfferDesc(JSON.parse(localStorage.home_offer_desc));
    }, [])

    return (
        <>
            <div className="home__slide">
                <div className="container">
                    <div className="col-12">
                        <div className="home__slide__inner">
                            <div className="home__slide__inner--text">
                                <span>Meble dopasowane do Twoich potrzeb</span>
                                <p>Systemy szaf przesuwnych i&nbsp;otwieranych, garderoby oraz inne meble na wymiar o&nbsp;wysokiej estetyce i&nbsp;praktycznych
rozwiązaniach.
                                </p>
                            </div>
                            <div className="home__slide__inner--line"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home__about">
                <div className="container">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6 col-12 my-auto">
                                <div className="home__about--text">
                                    <span>O nas</span>
                                    <p>Od ponad 25 lat zajmujemy się produkcją i&nbsp;montażem szaf przesuwnych i&nbsp;otwieranych oraz mebli na wymiar
najwyższej jakości których trwałość, elegancja oraz funkcjonalność dostarczyły nam wielu zadowolonych i&nbsp;stałych
klientów. Od pierwszych dni istnienia stawiamy na spełnienie Państwa potrzeb. Zawsze wychodzimy do Państwa z&nbsp;coraz to bogatszą i&nbsp;ciekawszą ofertą.<br></br><br></br>Oferujemy pomiar, wycenę oraz projekt za darmo. Zadzwoń już dziś i&nbsp;dowiedz się co możemy dla Ciebie zrobić.</p>
                                </div>
                                <div className="home__about--line"></div>
                            </div>
                            <div className="col-lg-6 col-12 my-auto">
                                <img src="https://domserwismeble.pl/domserwismeble/storage/uploads/2022/01/07/o-nas_uid_61d884d51b05b.png" loading="lazy" alt="" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home__offer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="home__offer--heading">
                                <span>Oferta</span>
                            </div>
                        </div>
                        {
                            homeOffer.map((value: any, key: any) => {
                                let image = api_domain + Object.values(value.offer_image);
                                let desc = Object.values(value.offer_name);
                                let link = "";

                                if(key === 0) {
                                    link = "/gallery/61d88319353665183900022b"
                                }
                                else if(key === 1) {
                                    link = "/gallery/61d8833e6239621d62000067"
                                }
                                else if(key === 2) {
                                    link = "/gallery/61d88331383332b147000025 "
                                }
                                else if(key === 3) {
                                    link = "/gallery/61d883476238351386000165"
                                }

                                return (
                                   <div key={key} className="col-lg-6 col-12">
                                        <div className="home__offer_inner">
                                            <div className="home__offer_inner--text">
                                                <span>{desc}</span>
                                            </div>
                                            <div className="home__offer_inner--img">
                                            <a style={{cursor: 'pointer'}} onClick={() => {window.location.href=link}}><img className={key === 0 ? 'btl-radius' : ''} loading="lazy" src={image} alt=""></img></a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            homeOfferDesc.map((value: any, key: any) => {
                                let title = Object.values(value.Title);
                                let content = Object.values(value.Content);
                                let image = api_domain + value.Image.path
                                return (
                                    <div key={key} className="col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-12 mt-4">
                                                <div className="home__offer_text">
                                                    <div className="home__offer_text--left">
                                                        <span>{title}</span>
                                                        <p>{content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mt-4 my-auto">
                                                <div className="home__offer_inner">                                                    
                                                    <div className="home__offer_inner--img">
                                                        <img className="bbr-radius" src={image} loading="lazy" alt=""></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="contact__top"></div>

            <div className="contact__box">
                <div className="container">
                    <div className="contact__box__inner">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="contact__box__inner--text mb-4">
                                            <span>Pokaż nam swój pomysł!</span>
                                            <p>Skontaktuj się z nami za pomocą formularza kontaktowego, a my odpowiemy najszybciej jak to możliwe.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <Formik
                                            initialValues={{ v_name_surname: "", v_phone: "", v_email: "", v_message: "" }}
                                            onSubmit={values => {
                                                axios.post('https://domserwismeble.pl/mail_api/send',
                                                    JSON.stringify(
                                                        {
                                                            name_surname: values.v_name_surname,
                                                            phone: values.v_phone,
                                                            email: values.v_email,
                                                            message: values.v_message
                                                        }
                                                    ),
                                                    {
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        }
                                                    }
                                                ).then((res: any) => {
                                                    if (res.data.code == 200) {
                                                        window.location.href = "/successfully";
                                                        window.scrollTo(0, 0);
                                                    }
                                                })
                                            }}
                                        >
                                            {
                                                ({
                                                    values,
                                                    handleChange,
                                                    handleSubmit,
                                                }) => (
                                                    <form onSubmit={handleSubmit} className="contact__box__inner--form">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-12">
                                                                <div className="mb-4">
                                                                    <input onChange={handleChange} type="text" name="v_name_surname" className="form-control" placeholder="Imię i Nazwisko" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-12">
                                                                <div className="mb-4">
                                                                    <input onChange={handleChange} type="text" name="v_phone" className="form-control" placeholder="Telefon" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="mb-4">
                                                                    <input onChange={handleChange} type="text" name="v_email" className="form-control" placeholder="Email" required />
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="mb-4">
                                                                    <textarea onChange={handleChange} name="v_message" className="form-control" placeholder="Treść" required></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="mb-4">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="gridCheck" required></input>
                                                                        <label className="form-check-label" htmlFor="gridCheck">
                                                                        <small>Kliknięcie przycisku "Wyślij wiadomość" oznacza zgodę na przetwarzanie danych osobowych zgodnie z&nbsp;ustawą o&nbsp;ochronie danych osobowych w&nbsp;związku z&nbsp;wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest podmiot Firma Dom Serwis.</small>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="submit" className="btn btn-primary">Wyślij wiadomość</button>
                                                    </form>
                                                )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;