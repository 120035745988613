import './../node_modules/bootstrap/dist/js/bootstrap.bundle'
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Header from './components/_partials/Header';
import Footer from './components/_partials/Footer';
import Page from './components/Page/Page';
import Home from './components/Home/Home';
import Gallery from 'components/Gallery/Gallery';
import Albums from 'components/Realizations/Realizations';
import './App.scss';

function App() {
  return (
    <div className="App">
      <GoogleReCaptchaProvider
        reCaptchaKey="6Ld7uIEcAAAAAD67Wo1BGasEvRaCtA7GAvQ_4RZh"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
      >
        <Header />
        <Router>
          <Switch>
            <Route exact path='/realizations' render={() => <Albums />} />
            <Route exact path='/gallery/:id' render={(props) => <Gallery {...props} />} />
            <Route exact path='/:page' component={Page} />
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
