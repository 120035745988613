import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Realizations = () => {

    document.title = 'Realizacje - Firma Dom Serwis';

    const api_domain = "https://domserwismeble.pl";
    const [gallery, setGallery] = useState<any>([]);

    useEffect(() => {
        axios.get(api_domain + "/domserwismeble/api/collections/get/Gallery?&token=f7edd2e09e8bc0678ef2ea908889d3")
            .then(function (response) {
                setGallery(response.data.entries)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    return (
        <>
            <div className="container">
                <div className="subpage__heading">
                    <div className="subpage__content">
                        <div className="row">
                            <div className="col-lg-12 mb-4">
                                <h1>Realizacje</h1>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    {
                                        gallery.map((value: any, key: any) => {
                                            return (
                                                <div key={key} className="col-lg-6 col-12 mb-4">
                                                    {
                                                        value.Images.map((v: any, k: any) => {
                                                            if (k === 0) {
                                                                return (
                                                                    <div key={k} className="subpage__content__realizations-item">
                                                                        <Link to={'/gallery/' + value._id}>
                                                                            <div className='subpage__content__realizations-item--single btl-80-img' style={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0) 70%, rgba(0,0,0,0.9)), url(' + api_domain + v.path + ')' }}>
                                                                                <span>{value.gallery_name}</span>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact__top"></div>
        </>

    )
}

export default Realizations;