import React, { useState, useEffect } from "react";
import axios from "axios";

const Header = () => {

  const api_domain = "https://domserwismeble.pl";
  const [menu, setMenu] = useState([]);
  const [logo, setLogo] = useState("https://domserwismeble.pl/logo/logo.svg");
  const [scolor, setSColor] = useState(false);
  const pageName: string = String(window.location.pathname).substr(1);

  let hours = 1;
  let now: any = new Date().getTime();
  let setupTime: any = localStorage.getItem('setupTime');

  useEffect(() => {
    if (setupTime == null) {
      localStorage.clear();
      localStorage.setItem('setupTime', now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem('setupTime', now);
        window.location.reload();
      }
    }
  }, [])

  const fetchDataCall = async () => {
    let apiReturn = await axios
      .get(api_domain + "/domserwismeble/api/collections/get/Page?token=f7edd2e09e8bc0678ef2ea908889d3")
      .then(async function (response) {
        localStorage.setItem('content', JSON.stringify(response.data.entries));
      })
      .catch(function (error) {
        console.log(error);
      });
    return apiReturn;
  };

  const fetchMenuCall = async () => {
    let apiReturn = await axios
      .get(api_domain + "/domserwismeble/api/collections/get/Menu?token=f7edd2e09e8bc0678ef2ea908889d3")
      .then(async function (response) {
        localStorage.setItem('menu', JSON.stringify(response.data.entries));
      })
      .catch(function (error) {
        console.log(error);
      });
    return apiReturn;
  };

  if (localStorage.getItem("content") === null) {
    fetchDataCall();
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }

  if (localStorage.getItem("menu") === null) {
    fetchMenuCall();
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }

  useEffect(() => {
    setMenu(JSON.parse(localStorage.menu));
  }, [localStorage.menu])

  useEffect(() => {
    if (pageName.length > 0) {
      setLogo("https://domserwismeble.pl/logo/logo_black.svg");
      setSColor(true)
    }
    else {
      setLogo("https://domserwismeble.pl/logo/logo.svg");
      setSColor(false)
    }
  }, [])

  return (
    <>
      <nav className={"navbar navbar-expand-lg navbar-light " + (scolor ? 'header_black' : 'header')}>
        <div className="container">
          <a className="navbar-brand" href="/">
            <img width="100" src={logo} alt="" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="/">Strona główna</a>
              </li>
              {
                menu.map((value: any, key: any) => {
                  return (<li key={key} className="nav-item">
                    <a href={"/" + value.link} className="nav-link" style={{ color: scolor + '!important' }} aria-current="page">{value.Item_name}</a>
                  </li>)
                })
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header;