import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Formik } from "formik";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from "react-router-dom";

const Page = () => {

    const api_domain = "https://domserwismeble.pl";
    const pageName: string = String(window.location.pathname).substr(1);
    const content = JSON.parse(localStorage.content);

    const [pageRender, setPageRender] = useState<any>();

    const fetchHomeOfferCall = async () => {
        let apiReturn = await axios
            .get(api_domain + "/domserwismeble/api/collections/get/Home_offer?token=f7edd2e09e8bc0678ef2ea908889d3")
            .then(async function (response) {
                localStorage.setItem('home_offer', JSON.stringify(response.data.entries));
            })
            .catch(function (error) {
                console.log(error);
            });
        return apiReturn;
    };

    const fetchHomeOfferDescCall = async () => {
        let apiReturn = await axios
            .get(api_domain + "domserwismeble/api/collections/get/Home_offer_desc?token=f7edd2e09e8bc0678ef2ea908889d3")
            .then(async function (response) {
                localStorage.setItem('home_offer_desc', JSON.stringify(response.data.entries));
            })
            .catch(function (error) {
                console.log(error);
            });
        return apiReturn;
    };

    if (localStorage.getItem("home_offer") === null) {
        fetchHomeOfferCall();
        setTimeout(function () {
            window.location.reload();
        }, 200);
    }

    if (localStorage.getItem("home_offer_desc") === null) {
        fetchHomeOfferDescCall();
        setTimeout(function () {
            window.location.reload();
        }, 200);
    }

    useEffect(() => {
        content.map((value: any, key: any) => {
            if (value.Link === pageName) {
                document.title = String(value.Title) + ' - Firma Dom Serwis';
            }
        });
    }, [])

    useEffect(() => {
        content.map((value: any, key: any) => {
            if (value.Link === pageName && pageName !== "contact") {
                if (value.Image) {
                    localStorage.setItem('pageName', String(value.Title));
                    document.title = String(localStorage.getItem('pageName')) + ' - Firma Dom Serwis';
                    setPageRender(
                        <div key={key}>
                            <div className="subpage__heading"></div>
                            <div className="subpage__content">
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <h1>{ReactHtmlParser(value.Title)}</h1>
                                        {ReactHtmlParser(value.Content)}
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <LazyLoadImage effect="blur" className="sticky-top btl-80-img" alt="" src={api_domain + value.Image.path} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                else {
                    setPageRender(
                        <div key={key}>
                            <div className="subpage__heading"></div>
                            <div className="subpage__content">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1>{ReactHtmlParser(value.Title)}</h1>
                                        {ReactHtmlParser(value.Content)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            if (value.Link === pageName && pageName === "contact") {
                setPageRender(
                    <div key={key}>
                        <div className="subpage__heading"></div>
                        <div className="subpage__content">
                            <div className="row">
                                <div className="col-12">
                                    <h1>{ReactHtmlParser(value.Title)}</h1>
                                </div>
                                <div className="col-lg-3 col-12 mb-5">
                                    {ReactHtmlParser(value.Content)}
                                </div>
                                <div className="col-lg-9 col-12 mb-lg-5 mb-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.2731130013503!2d15.375619215900596!3d51.066508250663496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470f2b3f0a5eb43d%3A0xbb450f1388a4f821!2sLegnicka%208%2C%2059-830%20Olszyna!5e0!3m2!1spl!2spl!4v1642114801144!5m2!1spl!2spl" width="100%" height="325" loading="lazy"></iframe>
                                </div>
                                <div className="col-12 mt-5 mt-lg-0">
                                    <h2 className="mb-5">Formularz kontaktowy</h2>
                                    <Formik
                                        initialValues={{ v_name_surname: "", v_phone: "", v_email: "", v_message: "" }}
                                        onSubmit={values => {
                                            axios.post('https://domserwismeble.pl/mail_api/send',
                                                JSON.stringify(
                                                    {
                                                        name_surname: values.v_name_surname,
                                                        phone: values.v_phone,
                                                        email: values.v_email,
                                                        message: values.v_message
                                                    }
                                                ),
                                                {
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    }
                                                }
                                            ).then((res: any) => {
                                                if (res.data.code == 200) {
                                                    window.location.href = "/successfully";
                                                    window.scrollTo(0, 0);
                                                }
                                            })
                                        }}
                                    >
                                        {
                                            ({
                                                values,
                                                handleChange,
                                                handleSubmit,
                                            }) => (
                                                <form onSubmit={handleSubmit} className="contact__box__inner--contact-form">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-12">
                                                            <div className="mb-4">
                                                                <input onChange={handleChange} type="text" name="v_name_surname" className="form-control" placeholder="Imię i Nazwisko" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="mb-4">
                                                                <input onChange={handleChange} type="text" name="v_phone" className="form-control" placeholder="Telefon" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="mb-4">
                                                                <input onChange={handleChange} type="text" name="v_email" className="form-control" placeholder="Email" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="mb-4">
                                                                <textarea onChange={handleChange} name="v_message" className="form-control" placeholder="Treść" required></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="mb-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="gridCheck" required></input>
                                                                    <label className="form-check-label" htmlFor="gridCheck">
                                                                    <small>Kliknięcie przycisku "Wyślij wiadomość" oznacza zgodę na przetwarzanie danych osobowych zgodnie z&nbsp;ustawą o&nbsp;ochronie danych osobowych w&nbsp;związku z&nbsp;wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest podmiot Firma Dom Serwis.</small>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary">Wyślij wiadomość</button>
                                                </form>
                                            )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })
    }, [])

    return (
        <>
            <div className="subpage">
                <div className="container">
                    {pageRender ? pageRender : (<>
                        <div className="subpage__heading"></div>
                        <div className="subpage__content">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h1>Strona, której szukasz nie istnieje</h1>
                                    <p>Przepraszamy, ale strona której szukasz nie istnieje.<br></br>
                                        Może być to spowodowane nieprawidłowym odnośnikiem na stronie, z której tu trafiłeś.</p>
                                    <Link to="/">
                                        Wróć do strony głównej
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>)}
                </div>
            </div>

            <div className="contact__top"></div>
        </>
    )
}

export default Page;